// Refer to https://create-react-app.dev/docs/adding-a-css-modules-stylesheet
// On how to use scss modules with React

// Import WMN Design System scss for use in this scss file
// Tilde (~) represents the root folder(src)
@import '~assets/wmnds/vars';
@import '~assets/wmnds/mixins';

.autoCompleteContainer {
  position: relative;
  flex-grow: 1;
}

@media screen and (max-width: $breakpoint-md - 1) {
  .desktopReset {
    display: none !important;
  }
}

.mobileReset {
  position: absolute;
  top: 0;
  right: 0;
  width: 3rem;
  height: 52px;
  border: 0;
  background: none;

  svg {
    width: 1.5rem;
    height: 1.5rem;
    fill: get-color(secondary);
  }
}

.leftAlignBtn {
  display: flex;
  min-height: 50px;
  padding-top: 0;
  padding-bottom: 0;
  text-align: left;
  justify-content: space-between;
  align-items: center;

  svg {
    flex: 0 0 22px;
  }
}
